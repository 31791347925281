<template>
  <div>
    <b-modal id="dialog_deliveries_get_pic_supplier" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> (2) รับรูปจัดเสร็จจากซัพพลายเออร์</h3>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="mb-4">
            <label class="col-form-label label_algin_right">อัพโหลดภาพ
            <span class="text-xs text-danger">(กดที่รูปภาพเพื่อเปลี่ยนรูป)</span></label>
            <div class="card">
              <div class="card-body m-auto" >
                <input type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler" multiple/>
                <div v-for="(img, index) in item.photo_before_encode" :key="`list_photo_before_${index}`" class="position-relative float-left">
                  <i class="fa fa-times text-danger position-absolute" style="right: 3px; top: 3px;" @click="deleteImageHandler(img, index)"></i>
                  <img :src="img.url" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandler()">
                </div>
                <img v-if="item.photo_before_encode.length === 0" :src="noImageData" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandler()">
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="clickUploadImages()" class="style_btn" style="width: 125px;" :disabled="files.length==0"><i class="fas fa-upload"></i> อัพโหลดภาพ</b-button>
        <b-button variant="primary" @click="clickModalOnhold()" class="style_btn" style="width: 260px;"><i class="fa fa-check"></i> รับรูปจัดเสร็จจากซัพพลายเออร์แล้ว</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import $ from 'jquery';
import Constants from '../../../util/constants';
import StringUtils from '../../../util/StringUtils';
export default {
  name: 'delivery-view-dialog-geted_pic_supplier',
  data () {
    return {
      noImageData: Constants.noImageData,
      files: [],
      deletes: []
    }
  },
  props: ['item'],
  methods: {
    initData(photos){
      this.files = JSON.parse(JSON.stringify(photos));
    },
    deleteImageHandler(img, index){
      this.item.photo_before_encode.splice(index, 1);
      this.files.splice(index, 1);
      if(img.id){
        this.deletes.push(img.id);
        if(img.id_t){
          this.deletes.push(img.id_t);
        }
      }
    },
    uploadFileHandler(){
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler (event) {
      let img = event.target.files;
      if (img.length > 0) {
        const vm = this;
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = function(event) {
            vm.item.photo_before_encode.push({url:event.target.result});
          }
          reader.readAsDataURL(img[i]);
          vm.files.push(img[i]);
        }
      }
    },
    async clickModalOnhold () {
      const result = await this.HttpServices.putData(`/ab_delivery/updateSendToSupplier/${this.item.id}`);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alert("success", "รับรูปจัดเสร็จจากซัพพลายเออร์สำเร็จ");
        this.$bvModal.hide('dialog_deliveries_get_pic_supplier');
        this.$emit('completed', true);
      }else{
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close () {
      this.$bvModal.hide(`dialog_deliveries_get_pic_supplier`);
    },
    async clickUploadImages () {
      if(this.files.length==0){
        this.AlertUtils.alert("warning", "กรุณาเลือกไฟล์เพิ่มเติม");
        return;
      }
      this.SpinnerService.showLoader();
      const s3Auth = await this.HttpServices.authenS3();
      if(s3Auth._info){
        const medias = [];
        const accessToken = s3Auth._info.secret.accessToken;
        for(const v of this.files){
          // const params = new FormData();
          // params.append("fileUpload", v);
          // await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoBefore/${this.item.id}`, params);
          if(v instanceof File){
            let params = new FormData();
            params.append("file", v);
            params.append("title", v.name);
            params.append("file_category", 'order-delivery');
            const resultMedia = await this.HttpServices.postFormDataS3(params, accessToken);
            if(resultMedia.media&&resultMedia.media.length>0){
              const media = resultMedia.media[0];
              const mediaUpload = {
                id: media.id,
                original_name: media.original_name,
                mime_type: media.mime_type,
                size: media.size,
                url: media.url
              };

              params = new FormData();
              const thumbFile = await StringUtils.resizeBlobToFile({file: v, maxSize: 200});
              params.append("file", thumbFile);
              params.append("title", thumbFile.name);
              params.append("file_category", 'order-delivery');
              let resultThumb = await this.HttpServices.postFormDataS3(params, accessToken);
              if(resultThumb.media&&resultThumb.media.length>0){
                const mediaThumb = resultThumb.media[0];
                mediaUpload.id_t = mediaThumb.id;
                mediaUpload.original_name_t = mediaThumb.original_name;
                mediaUpload.mime_type_t = mediaThumb.mime_type;
                mediaUpload.size_t = mediaThumb.size;
                mediaUpload.url_t = mediaThumb.url;
              }
              medias.push(mediaUpload);
            }
          }else{
            const mediaUpload = {
              id: v.id,
              original_name: v.original_name,
              mime_type: v.mime_type,
              size: v.size,
              url: v.url
            };
            if(v.id_t){
                mediaUpload.id_t = v.id_t;
                mediaUpload.original_name_t = v.original_name_t;
                mediaUpload.mime_type_t = v.mime_type_t;
                mediaUpload.size_t = v.size_t;
                mediaUpload.url_t = v.url_t;
            }
            medias.push(mediaUpload);
          }
        }
        if(medias){
          await this.HttpServices.putFormData(`/ab_delivery/uploadPhotoBefore/${this.item.id}`, {media: JSON.stringify(medias)});
        }
        if(this.deletes&&this.deletes.length>0){
          for(const v of this.deletes){
            await this.HttpServices.deleteDataS3(v, accessToken);
          }
        }
        this.files = [];
        this.AlertUtils.alert('success', `อัพโหลดภาพสำเร็จ`);
        this.$emit('completed', true);
      }else{
        this.AlertUtils.alert('error', `กรุณาตรวจสอบการอัพโหลดผ่าน S3`);
      }
      this.SpinnerService.hideLoader();
    }
  }
}
</script>
