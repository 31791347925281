<template>
  <div>
    <b-modal id="dialog_deliveries_send_customer" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> (5) ส่งให้ลูกค้าแล้ว</h3>
      </template>
      <div class="text-center">
        <p>ยืนยันการส่งให้ลูกค้าแล้ว</p>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saved()" class="style_btn"
          ><i class="fa fa-check"></i> ตกลง</b-button
        >
        <b-button variant="secondary" @click="close()"
          ><i class="fa fa-times"></i> ยกเลิก</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "delivery-view-dialog-send-customer",
  data() {
    return {};
  },
  props: ["item"],
  methods: {
    async saved() {
      const result = await this.HttpServices.putData(
        `/ab_delivery/updateSendCustomer/${this.item.id}`
      );
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "ยืนยันการส่งให้ลูกค้าแล้ว");
        this.$bvModal.hide("dialog_deliveries_send_customer");
        this.$emit("completed", true);
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close() {
      this.$bvModal.hide(`dialog_deliveries_send_customer`);
    }
  }
};
</script>
