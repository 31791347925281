<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5 mb-4">
            <b-dropdown id="dropdown-1" text="สถานะการจัดส่ง" class="dropdown_input_search mb-1" @show="getFilter">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;" class="custom-dropdown-group">
                  <li
                    v-for="v in criteria.status_filters"
                    :key="v.id"
                    style="padding: .5rem 1rem; font-size: 0.875rem;"
                  >
                    <input
                      type="checkbox"
                      class="checkbox_search_input"
                      :name="`checkbox_date${v.id}`"
                      :id="`checkbox_date${v.id}`"
                      v-model="v.selected"
                      @change="searchHandler()"
                    />
                    <label
                      class="form-check-label"
                      v-bind:for="`checkbox_date${v.id}`"
                    >{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>


            <b-dropdown id="dropdown-store-filter" text="ร้านค้า" class="dropdown_input_search ml-1 mb-1" @show="getFilter">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;width:400px;">
                  <li v-for="v in criteria.store_filters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;">
                    <input 
                      type="checkbox" class="checkbox_search_input" v-model="v.selected"
                      :name="`checkbox_store${v.id}`"
                      :id="`checkbox_store${v.id}`"
                      @change="searchHandler()"
                    />
                    <label class="form-check-label" v-bind:for="`checkbox_store${v.id}`" >
                      {{ v.name }}
                    </label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <Select2Supplier :value="criteria.supplier" ref="Select2Supplier" @change="getSupplierById"></Select2Supplier>
          </div>
          <div class="col-sm-2">
            <date-picker
              :format="'DD/MM/YYYY'"
              v-model="criteria.order_date"
              class="w-100"
              input-class="form-control"
              placeholder="วันที่แสดงผล"
              :append-to-body="false"
              :popup-style="{ left: 0 }"
            ></date-picker>
          </div>
          <!-- <div class="col-sm-3"></div> -->
          <div class="col-sm-5">
            <b-input-group>
              <b-input-group-append>
                <b-input-group-text class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input
                placeholder="ระบุการค้นหา เช่น รหัสสั่งซื้อ"
                v-model="criteria.search"
                class="form-control-group"
                @keyup.enter.native="searchHandler()"
              ></b-form-input>
              <b-input-group-append>
                <b-button
                  size="md"
                  text="Button"
                  variant="primary"
                  @click="searchHandler()"
                  class="btn_border_radius_right_top_bt"
                  style="width: 86px;"
                >ค้นหา</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5" />
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน:
              <strong style="color: #000000; font-weight: bold;">{{ countFilter() }} รายการ</strong>
              จาก {{ paging.rowTotal | numberIntFormat }} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.status_filters">
              <span
                class="badge bage_selected_checkbox_search m-2"
                :key="`name_comp${index}`"
                v-if="select.selected"
              >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span
                  class="ml-3"
                  style="font-size: 8px; color: #1C9EE7;"
                  @click="unSelectFilter(select)"
                >
                  <i
                    class="fa fa-times icon-del"
                    style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"
                  ></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.store_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`color${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)" >
                  <i
                    class="fa fa-times icon-del"
                    style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"
                  ></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div
              class="label_algin_right text-underline m-2"
              style="color: #848484; cursor: pointer;"
              @click="unSelectAllFilter()"
            >ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="pb-4 text-right">
      <a  href="#/supplier-mapping/product/add"
          class="btn btn-primary">
        <i class="fa fa-plus"></i> เพิ่ม
      </a>
      <button type="button"
                class="btn btn-danger style_btn"
                @click="remove()">
        <i class="far fa-trash-alt"></i>
      </button>
    </div>-->
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table id="tblDelivery" class="table align-middle table-bordered">
              <thead>
                <tr class="text-center">
                  <!-- <th style="min-width: 50px; width: 1px;">เลือก</th> -->
                  <th>รหัสสั่งซื้อ</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                  <th>วันที่จัดส่ง</th>
                  <th>เวลาจัดส่ง</th>
                  <th>ชื่อร้านค้า</th>
                  <th>ชื่อ Supplier</th>
                  <th>ชื่อผู้รับ</th>
                  <th>เบอร์</th>
                  <th>ขื่อผู้สั่ง</th>
                  <th>Line (ผู้สั่ง)</th>
                  <th>Email (ผู้สั่ง)</th>
                  <th>สถานะการชำระเงิน</th>
                  <th>สถานะการจัดส่ง</th>
                  <th>ก่อนอัพโหลด</th>
                  <th>หลังอัพโหลด</th>
                  <th>เวลาส่งจริง</th>
                  <th>หมายเหตุ</th>
                  <th>ยืนยันการตรวจสอบ</th>
                  <th>เวลาแก้ไขล่าสุด</th>
                  <th>รายละเอียด</th>
                </tr>
              </thead>
              <tbody v-if="!onSearching">
                <tr v-for="(data, index) in items" :key="`list_deliveries_${index}`"
                :style="`${(data.status_order == 6 && permission.actionData.flag_update) ? 'background-color: #D6EAF8;' :
                    (data.status_order == 7 && permission.actionData.flag_update) ? 'background-color:  #FADBD8;' :
                  'background-color:  #FFFFFF;' }`"
                >
                  <td class="text-center">
                    <a
                      href="javascript:void(0)"
                      class="nav-link text-underline"
                      @click="onViewDetailHandler(data)"
                    >{{ data.code }}</a>
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i
                        class="fas fa-step-forward"
                        :title="
                          StringUtils.getTextDeliveriesStatus(data.status).text
                        "
                        @click="onWorkflowStepHandler(data, index)"
                        v-if="onCheckWorkflowStepHandler(data)"
                      ></i>
                    </span>
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="กำหนดราคาขาย"></i>
                    </span>
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-exclamation-circle"
                        title="แจ้งปัญหา"
                        @click="onIssueHandler(data)"
                      ></i>
                    </span>
                    <span class="style_icon_btn">
                      <i class="fas fa-list" title="รายละเอียด" @click="onViewDetailHandler(data)"></i>
                    </span>
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-comment"
                        title="ข้อมูลการสั่งซื้อ"
                        @click="onViewPaymentHandler(data)"
                      ></i>
                    </span>
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-comments"
                        title="รายละเอียดการสั่งซื้อ"
                        @click="confirmOrderHandler(data)"
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">{{ data.expected_delivery_date }}</td>
                  <td class="text-center">{{ data.expected_delivery_time }}</td>
                  <td class="text-center">{{ data.store_name }}</td>
                  <td class="text-center">{{ data.supplier_name }}</td>
                  <td class="text-center">{{ data.delivery_contact }}</td>
                  <td class="text-center">{{ data.delivery_phone }}</td>
                  <td class="text-center">{{ data.customer_name }}</td>
                  <td class="text-center">{{ data.line }}</td>
                  <td class="text-center">{{ data.email }}</td>
                  <td class="text-center">
                    {{
                    StringUtils.getTextPaymentStatus(data.status_payment).text
                    }}
                  </td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i
                        :class="
                          StringUtils.getIconDeliveriesStatus(data.status)
                        "
                        :title="
                          StringUtils.getTextDeliveriesStatus(data.status).text
                        "
                      ></i>
                    </span>
                  </td>
                  <td class="text-center">
                    <img
                      v-if="data.photo_before"
                      :src="
                        data.photo_before_encode[0]
                          ? data.photo_before_encode[0].url_t
                            ? data.photo_before_encode[0].url_t
                            : data.photo_before_encode[0].url
                          : noImageData
                      "
                      style="width: 30px;"
                      @click="onImagePreviewHandler(data.photo_before_encode)"
                    />
                  </td>
                  <td class="text-center">
                    <img
                      v-if="data.photo_after"
                      :src="
                        data.photo_after_encode[0]
                          ? data.photo_after_encode[0].url_t
                            ? data.photo_after_encode[0].url_t
                            : data.photo_after_encode[0].url
                          : noImageData
                      "
                      style="width: 30px;"
                      @click="onImagePreviewHandler(data.photo_after_encode)"
                    />
                  </td>
                  <td class="text-center">{{ data.actual_delivery_date_short }} {{ data.actual_delivery_time_short  }}</td>
                  <td class="text-right">{{ data.remark }}</td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class=" StringUtils.getIconInspectionStatus( data.status_inspection ) " :title=" StringUtils.getTextInspectionStatus( data.status_inspection ).text " ></i>
                    </span>
                  </td>
                  <td class="text-right">{{ data.updatedtime }}</td>
                  <td class="text-left">
                    <div v-for="item in data.order_item" :key="item.id">
                      <span v-if="item.parent == null">
                      <b>{{ item.product_code }}</b> <br> 
                      ข้อความ 1 : {{ item.msg_fr }} <br> 
                      ข้อความ 2 : {{ item.msg_se }} <br> 
                      รายละเอียด : {{ item.caption }}
                      </span>
                    </div>
                  </td>
                </tr>                
              </tbody>
              <tbody v-if="!onSearching && items.length == 0">
                <tr class="text-center">
                  <td colspan="13" v-if="items.length === 0 && !loading && !onSearching">ไม่มีข้อมูล</td>                  
                </tr>
              </tbody>
              <tbody v-if="onSearching">
                <tr>
                  <td colspan="13" class="text-center">
                    <b-spinner label="Loading..."></b-spinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-4 mt-4 custom-card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h3>สถานะการจัดส่ง</h3>
              <div v-for="(v, index) in deliveries_status" :key="`list_deliveries_status_${index}`">
                <i :class="StringUtils.getIconDeliveriesStatus(v.id)"></i>
                {{ v.id }} "{{ v.text }}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
          <select
            class="form-control mb-2"
            id="pageLimit"
            style="width: 80px;"
            v-model="paging.limit"
            v-on:change="changeLimit()"
          >
            <option v-for="data in paging.pages" :key="data" :value="data">{{ data }}</option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3" v-if="paging.rowTotal > 0">
            <div class="col-xl-11 col-lg-10 mb-2 text-right" style="margin-top: 30px;">
              <label
                class="form-label"
                style="margin: 8px; font-size: 12px;font-family: Sarabun-Light;color: #818181;"
              >
                แสดงรายการที่
                <strong>
                  {{ (paging.page - 1) * paging.limit + 1 }} ถึง
                  {{ paging.page * paging.limit }}
                </strong>
                จาก
                <strong>{{ paging.rowTotal | numberIntFormat }} รายการ</strong>
              </label>
              <paginate
                v-model="paging.page"
                :first-last-button="true"
                :page-count="paging.pageTotal"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'&lt;'"
                :next-text="'&gt;'"
                :first-button-text="'&verbar;&lt;'"
                :last-button-text="'&gt;&verbar;'"
                :click-handler="changePage"
                :container-class="
                  'pagination pagination-sm float-right m-auto paging_ul'
                "
                :page-class="'page-link'"
                :prev-class="'page-link prev_paging'"
                :next-class="'page-link next_paging'"
              ></paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label
                class="form-label"
                style="font-size: 12px;font-family: Sarabun-Light;"
              >Go to page</label>
              <Select2
                v-model="paging.page"
                :options="paging.pageSizes"
                @change="changePage(paging.page)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <dialog-image-preview :dialogId="dialogId"
                          :image="image"/>
    <dialog-draf  :delivery="delivery"
                  :nameId="dialogName.draf"
                  :dialogId="modalId"
                  :funcs="funcs"
                  @agree-draf="getValueDraf" />
    <dialog-send-data-supplier  :nameId="dialogName.send"
                                :dialogId="modalId"
                                :funcs="funcs"
                                :delivery="delivery"
                                ref="send_data_supplier"
                                @agree-send-data="getValueSendData"/>
    <dialog-geted-pic-supplier  :funcs="funcs"
                                :delivery="delivery"
                                :nameId="dialogName.geted"
                                :dialogId="modalId"
                                ref="geted_pic_supplier"
                                @agree-geted="getValueGeted"/>
    <dialog-send-pic-customer :nameId="dialogName.sendPic"
                              :dialogId="modalId"
                              :funcs="funcs"
                              :delivery="delivery"
                              ref="send_pic_customer"
                              @agree-send-pic="getValueSendPic"/>
    <dialog-onhold  :delivery="delivery"
                    :nameId="dialogName.hold"
                    :dialogId="modalId"/>
    <dialog-get-pic-supplier  :delivery="delivery"
                              :nameId="dialogName.get"
                              :funcs="funcs"
                              :dialogId="modalId"
                              ref="get_pic_supplier"
                              @agree-get="getValueGet"/>
    <dialog-close :nameId="dialogName.close"
                  :dialogId="modalId"
                  :funcs="funcs"
                  :delivery="delivery"
                  @agree-close="getValueClose"
                  ref="close" />
    <dialog-find-problem  :nameId="dialogName.find"
    :dialogId="modalId"/>-->
    <dialog-draf :item="item" @completed="initialData" />
    <dialog-send-data-supplier
      ref="dialog_deliveries_send_data_supplier"
      :item="item"
      @completed="initialData"
    />
    <dialog-geted-pic-supplier
      ref="dialog_deliveries_get_pic_supplier"
      :item="item"
      @completed="initialData"
    />
    <dialog-send-pic-customer :item="item" @completed="initialData" />
    <dialog-get-pic-supplier
      ref="dialog_deliveries_geted_pic_supplier"
      :item="item"
      @completed="initialData"
    />
    <dialog-find-problem :item="item" @completed="initialData" />
    <dialog-send-customer :item="item" @completed="initialData" />
    <dialog-onhold :item="item" @completed="initialData" />
    <dialog-close :item="item" @completed="initialData" />
    <dialogIssue ref="dialogIssue"/>
    <dialog-image-preview ref="imagePreview" />
    <dialogConfirmedOrder
      :item="order"
      :products="products"
      :suppliers="suppliers"
      :customer="customerSelected"
      ref="dialogOrderConfirm"
    />
  </div>
</template>
<script>
import mock from "../mock/delivery";

import dialogConfirmedOrder from "../../order/view/dialog.confirmed.order";
import dialogImagePreview from "./dialog.image.preview";
import dialogDraf from "./dialog.draf";
import dialogGetedPicSupplier from "./dialog.geted.pic.supplier";
import dialogOnhold from "./dialog.onhold";
import dialogGetPicSupplier from "./dialog.get.pic.supplier";
import dialogFindProblem from "./dialog.find.problem";
import dialogIssue from "../../issue/view/dialog.issue";
import dialogSendDataSupplier from "./dialog.send.data.supplier";
import dialogSendPicCustomer from "./dialog.send.pic.customer";
import dialogSendCustomer from "./dialog.send.customer";
import dialogClose from "./dialog.close";
import StringUtils from "../../../util/StringUtils";
import Constants from "../../../util/constants";
import Config from "../../../util/config";
import DateUtils from "../../../util/dateUtils";

import Select2Supplier from "../../supplier/component/select2.supplier";
export default {
  name: "delivery-view-page-delivery",
  data() {
    return {
      loading: true,
      onSearching: true,
      permission: {
        appSlug: "delivery",
        actionData: {}
      },
      noImageData: Constants.noImageData,
      StringUtils: StringUtils,
      criteria: {
        order_date: "",
        search: "",
        orderBy: "",
        ascDesc: "",
        status_filters: [],
        store_filters: [],
        supplier: null
      },
      deliveries_status: Constants.deliveries_status,
      inspections_status: Constants.inspections_status,
      items: [],
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      issue: {
        topic: "",
        priority: "0",
        remark: "",
        issue_type: ""
      },
      item: {
        photo_before_encode: [],
        photo_after_encode: []
      },
      show_photo_uploaded: [],
      products: [],
      suppliers: [],
      customerSelected: {},
      order: {}
    };
  },
  methods: {
    async getFilter(){ 
      await this.getFilterGroups()
    },
    async getSupplierById(supplier_id) {
      this.criteria.supplier = supplier_id;
      this.searchHandler();
      //this.criteria.supplier_filters = [supplier_id];
    },
    async onViewDetailHandler(data) {
      this.SessionStorageUtils.setSession( "page-delivery", JSON.stringify(data));
      this.$router.push(`/delivery/order_detail/${data.id}`);
    },
    async onViewPaymentHandler(data) {
      this.SessionStorageUtils.setSession("page-delivery",JSON.stringify(data));
      this.$router.push(`/delivery/payment_view/${data.id}`);
    },
    async onEditHandler(id) {
      this.SessionStorageUtils.setSession("page-delivery", id);
      this.$router.push(`/delivery/edit/${id}`);
    },
    onWorkflowStepHandler(data, index) {
      this.item = JSON.parse(JSON.stringify(data));
      this.show_photo_uploaded =
        JSON.parse(JSON.stringify(data)).photo_after_encode || [];
      if (data.status == 0) {
        this.$refs.dialog_deliveries_send_data_supplier.initData(this.item.store_id,this.item.order_id);
        this.$bvModal.show("dialog_deliveries_send_data_supplier");
      } else if (data.status == 1) {
        this.$refs.dialog_deliveries_get_pic_supplier.initData(
          this.item.photo_before_encode
        );
        this.$bvModal.show("dialog_deliveries_get_pic_supplier");
      } else if (data.status == 2) {
        this.$bvModal.show("dialog_deliveries_send_pic_customer");
      } else if (data.status == 3) {
        this.$refs.dialog_deliveries_geted_pic_supplier.initData(
          this.item.photo_after_encode
        );
        this.$bvModal.show("dialog_deliveries_geted_pic_supplier");
      } else if (data.status == 4) {
        this.$bvModal.show("dialog_deliveries_onhold");
      } else if (data.status == 5) {
        
        this.$bvModal.show("dialog_deliveries_send_customer");
      } else if (data.status == 6) {
        this.$bvModal.show("dialog_deliveries_close");
      }
    },
    onCheckWorkflowStepHandler(data) {
      if (
        data.status == 0 ||
        data.status == 1 ||
        data.status == 2 ||
        data.status == 3 ||
        data.status == 4 ||
        data.status == 5 ||
        data.status == 6
      ) {
        return true;
      }
      return false;
    },

    onIssueHandler(data) {
      this.$refs.dialogIssue.initIssue(3,data.id);
      this.$bvModal.show("dialog_issue");
    },

    onImagePreviewHandler(images) {
      this.$refs.imagePreview.onSetImagesHandler(images);
      this.$bvModal.show("dialog_deliveries_image_preview");
    },

    /******* local function ******/
    changeLimit() {
      this.paging.page = 1;
      this.getListItems();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListItems();
    },
    sortHandler(orderBy) {
      if (orderBy != this.criteria.orderBy) {
        this.criteria.ascDesc = "asc";
      } else {
        this.criteria.ascDesc = this.criteria.ascDesc == "asc" ? "desc" : "asc";
      }
      this.criteria.orderBy = orderBy;
      this.getListItems();
    },
    searchHandler() { 
      this.paging.page = 1;
      this.getListItems(); 
    },
    async getListItems() { 

      this.items = [];
      this.loading = true;
      let filters = [];
      this.criteria.status_filters.map(v => {
        if (v.selected) {
          filters.push(v.id);
        }
      });

      filters = filters.join(",");
      let params = `search=${this.criteria.search}&statusFilters=${filters}`;
      
      //StoreFilter
      filters = [];
      this.criteria.store_filters.map(v => {if (v.selected) {filters.push(v.id);}});
      params += `&storeFilter=` + filters.join(",");

      //SupplierFilter
      params += `&supplierFilter=` + this.criteria.supplier;
      
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;

      let order_date = DateUtils.dateFormat(
        this.criteria.order_date,
        "YYYY-MM-DD"
      );
      params += `&order_date=${order_date}`;
      this.onSearching = true;
      const result = await this.HttpServices.getData(`/ab_delivery?${params}`);
      this.onSearching = false;
      this.loading = false;
      if (result && result.status.code == "200") {        
        const data = result.data;

        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.pageTotal = total;
        this.paging.rowTotal = data.rowTotal;
        this.items = data.data;
        console.log('this.item in page deli',this.items);
        for (const v of this.items) {
          v.expected_delivery_date = DateUtils.dateFormat(
            v.expected_delivery_date,
            "DD/MM/YYYY"
          );
          // if(v.photo_before){
          //   const photoEncode = [];
          //   for(const vv of v.photo_before_encode){
          //     const img = `${vv}`;
          //     photoEncode.push(img);
          //   }
          //   v.photo_before_encode = photoEncode;
          // }
          // if(v.photo_after){
          //   const photoEncode = [];
          //   for(const vv of v.photo_after_encode){
          //     const img = `${vv}`;
          //     photoEncode.push(img);
          //   }
          //   v.photo_after_encode = photoEncode;
          // }
        }
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
        this.loading = false; 
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
      
    },
    checkDisabledDelete() {
      let resp = true;
      for (const v of this.items) {
        if (v.selected) {
          resp = false;
          break;
        }
      }
      return resp;
    },
    countFilter() {
      const filters = this.criteria.status_filters.filter(v => v.selected);
      return filters.length;
    },
    unSelectFilter(item) {
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter() {
      this.criteria.status_filters.map(v => {
        v.selected = false;
      });
      this.searchHandler();
    },
    
    async getFilterGroups() { 
      let storedFilterItem = localStorage.getItem("delivery-filterItem");
      let storedCountFilter = localStorage.getItem("delivery-countFilter");

      let result_filter, result;

      if (storedFilterItem && storedCountFilter) { 
        result_filter = { data: { store: JSON.parse(storedFilterItem) } };
        result = { data: { status: JSON.parse(storedCountFilter) } };
      } else { 
        result_filter = await this.HttpServices.getData(`/ab_delivery/getFilterItem`);
        result = await this.HttpServices.getData(`/ab_delivery/getCountFilter`);

        localStorage.setItem("delivery-filterItem", JSON.stringify(result_filter.data.store));
        localStorage.setItem("delivery-countFilter", JSON.stringify(result.data.status));
      }

      //this.criteria.store_filters = [];
      this.criteria.supplier = null;
      
      if(!this.criteria.store_filters.length){
        result_filter.data.store.forEach(item => {
        this.criteria.store_filters.push({
          id: item.id,
          name: item.name
        });
      });
      }
      

      const status = result.data.status;
      //this.criteria.status_filters = [];
      if(!this.criteria.status_filters.length){
        this.deliveries_status.forEach(v => {
        let lst = status.find(vv => vv.id === v.id);
        let filterCount = lst ? lst.filter_count : 0;
        this.criteria.status_filters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
      }
      
    },
    async confirmOrderHandler(data) {
      console.log(data);
      this.SpinnerService.showLoader();
      const result = await this.HttpServices.getData(`/ab_order/${data.order_id}`);
      if (result && result.status.code == "200") {
        this.order = result.data;
        if (this.order.date_order) {
          this.order.date_order = new Date(this.order.date_order);
        }
        if (this.order.expected_delivery_date) {
          this.order.expected_delivery_date = new Date(
            this.order.expected_delivery_date
          );
        }
        if (this.order.expected_delivery_time) {
          const tmps = this.order.expected_delivery_time.split(":");
          const d = new Date(this.order.expected_delivery_date);
          d.setHours(tmps[0]);
          d.setMinutes(tmps[1]);
          this.order.expected_delivery_time = d;
        }
        if (this.order.products) {
          this.products = JSON.parse(JSON.stringify(this.order.products));
          for (const v of this.products) {
            // if(v.logoImg){
            //   v.logoImg = `${Config.apiUrl}/resources/image/${v.logoImg}`;
            // }
            if (v.imageDisplays && v.imageDisplays.length > 0) {
              // const images = [];
              // for(const vv of v.imageDisplays){
              //   images.push(`${Config.apiUrl}/resources/image/${vv}`);
              // }
              // v.imageDisplays = images;
              v.imageFiles = [];

              for (const vv of v.products) {
                // if(vv.logoImg){
                //   vv.logoImg = `${Config.apiUrl}/resources/image/${vv.logoImg}`;
                // }
                if (vv.imageDisplays && vv.imageDisplays.length > 0) {
                  // const images = [];
                  // for(const ss of v.imageDisplays){
                  //   images.push(`${Config.apiUrl}/resources/image/${ss}`);
                  // }
                  // vv.imageDisplays = images;
                  vv.imageFiles = [];
                }
              }
            }
          }
        }
        if (this.order.suppliers) {
          this.suppliers = JSON.parse(JSON.stringify(this.order.suppliers));
        }
        if (this.order.customer) {
          this.customerSelected = this.order.customer;
        }

        //delete this.item.products;
        //delete this.item.suppliers;
        //delete this.item.customer;

        this.$refs.dialogOrderConfirm.getItem();
        this.$bvModal.show("dialog_confirmed_order");
      } else {
        this.AlertUtils.alert("warning", `ไม่พบข้อมูลการสั่งซื้อ`);
      }
      this.SpinnerService.hideLoader();
    },
    /******* local function ******/
    async initialData() {
     // this.getFilterGroups();
      this.getListItems();
    }
  },
  components: {
    Select2Supplier,
    dialogConfirmedOrder,
    dialogImagePreview,
    dialogDraf,
    dialogGetedPicSupplier,
    dialogOnhold,
    dialogGetPicSupplier,
    dialogFindProblem,
    dialogIssue,
    dialogSendDataSupplier,
    dialogSendPicCustomer,
    dialogClose,
    dialogSendCustomer
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.SessionStorageUtils.deleteSession("page-delivery");
    await this.initialData();
  }
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}


#tblDelivery tr>th:nth-child(1),#tblDelivery tr>td:nth-child(1) {
  position: sticky;
  left: 0;
  width: 146px;
  background-color: #fff; 
}
#tblDelivery tr>th:nth-child(2),#tblDelivery tr>td:nth-child(2) {
  position: sticky;
  left: 146px;
  width: 375px;
  background-color: #fff; 
}
#tblDelivery tr>th:nth-child(3) ,#tblDelivery tr>td:nth-child(3) {
  position: sticky;
  left: 375px;
  width: 492px;
  background-color: #fff; 
}

#tblDelivery tr>th:nth-child(4),#tblDelivery tr>td:nth-child(4) {
  position: sticky;
  left: 492px;
  background-color: #fff; 
}
#tblDelivery th,#tblDelivery td {
  outline: 1px solid #e9ecef;
  outline-offset: -1px;
}
.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
