<template>
  <div>
    <b-modal id="dialog_deliveries_send_data_supplier" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> (1) ส่งข้อมูลให้ซัพพลายเออร์</h3>
      </template>
     
      <div class="text-center">
        <div style="padding: 20px; display: none; " class="message_customer">
          {{ templateCustomer }}
        </div>

        <div style="padding: 20px; display: none;" class="message_supplier">
          {{ templateSupplier }}
        </div>
        <b-button
          pill
          variant="info"
          @click="copyToClipboardCustomer"
          id="button_copy"
          >คัดลอกสำหรับลูกค้า</b-button
        >
        <b-button
          pill
          variant="info"
          @click="copyToClipboardSupplier"
          id="button_copy"
          >คัดลอกสำหรับซัพพลายเออร์</b-button
        >
      </div>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="saved()"
          class="style_btn"
          style="width: 220px;"
          ><i class="fa fa-check"></i> ส่งข้อมูลให้ซัพพลายเออร์แล้ว</b-button
        >
        <b-button variant="secondary" @click="close()"
          ><i class="fa fa-times"></i> ยกเลิก</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import DateUtils from "../../../util/dateUtils";
import Constants from "../../../util/constants";
import NumberUtils from "../../../util/numberUtils";
import $ from "jquery";
export default {
  name: "delivery-view-dialog-send-data-supplier",
  data() {
    return {
      templateCustomer: "",
      templateSupplier: "",
      templateBill: "",
      products: "",
      customer: "",
      itemOrder: "",
      supplier: "",
      customerTypes: Constants.customer_types,
      billingTypes: Constants.bill_types
    };
  },
  props: ["item"],
  methods: {
    async initData(store_id, order_id) {
      await this.getItemOrder(order_id);
      await this.getItem(store_id);
    },
    async getItemOrder(order_id) {
      const result = await this.HttpServices.getData(`/ab_order/${order_id}`);
      if (result && result.status.code == "200") {
        
        this.products = result.data.products;
        this.customer = result.data.customer;
        this.supplier = result.data.supplier;
        this.itemOrder = result.data;
        this.item = result.data;
      } else {
        this.AlertUtils.alertCallback(
          "warning",
          `ไม่พบข้อมูลการสั่งซื้อ`,
          () => {
            this.$router.push("/delivery");
          }
        );
      }
    },

    async saved() {
      console.log("saved",this.item);
      const result = await this.HttpServices.putData(
        `/ab_delivery/updateDraft/${this.item.id}`
      );
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "Draft สร้างเพื่อเก็บข้อมูลสำเร็จ");
        this.$bvModal.hide("dialog_deliveries_send_data_supplier");
        this.$emit("completed", true);
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close() {
      this.$bvModal.hide(`dialog_deliveries_send_data_supplier`);
    },
    copyToClipboardCustomer(element) {
      let newClipTextBill = $(`.message_customer`).text();
      let str = `${newClipTextBill}`;

      navigator.clipboard.writeText(str);
    },
    copyToClipboardSupplier(element) {
      let newClipTextBill = $(`.message_supplier`).text();
      let str = `${newClipTextBill}`;

      navigator.clipboard.writeText(str);
    },
    msg_product_tag_code_price(product){ 
        let s = '';
        s += '- ';
        
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
          
        s += product.name;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.price);
        s += ' บาท';
        return s;
      },
      msg_product_tag_code_cost(product){
        let s = '';
        s += 'สั่ง ';
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
        s += ' รหัส: ';
        s += product.product_code;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.cost);
        s += ' บาท';
        return s;
      }, 
      msg_product_tag_code_with_order_detail_for_supplier_wreath(allProduct,product,isFirstProduct,product_children_cost){
        let deliveryLocation = '';
        let wreathSize = '';
        const google_map_url = this.item.google_map_url ? this.item.google_map_url : '';
        const temple_hall = this.item.temple_hall ? this.item.temple_hall : '';
        const caption = product.caption ? product.caption : '';
        const recipient = product.recipient ? product.recipient : '';
        const msg_fr = product.msg_fr ? product.msg_fr : '';
        const msg_se = product.msg_se ? product.msg_se : '';
        const deliveryTimeString = this.item.expected_delivery_time; 
        const [hours, minutes, seconds] = deliveryTimeString.split(':').map(Number);
        const deliveryTime = new Date();
        deliveryTime.setHours(hours, minutes, seconds || 0);
 
        deliveryTime.setHours(deliveryTime.getHours() + 1);

        const deliveryTimeAddOneHour = deliveryTime.toTimeString().split(' ')[0].slice(0, 5);
        if (product.tags) {
          const tag = product.tags.find(tag => tag.key === 'ขนาดพวงหรีด');
          wreathSize = tag && tag.value ? tag.value : '';
        }

        if(this.item.delivery_location){
          deliveryLocation += this.item.delivery_location + ' ';
        }
        if(this.item.delivery_address){
          deliveryLocation += this.item.delivery_address;
        }
        let s = '';
        s += 'ส่งออเดอร์()\n';
        s += 'เลขที่สั่งซื้อ: ' + this.item.code + '\n';
        s += '\nสั่ง ';
        if(product.tags && product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภทพวงหรีด"){
              s += tag.value;
            }
        }else{
          s += product.product_name;
        }
        s += ' รหัส: ';
        s += product.product_code;
        s += ' ส่งร้าน : ';
        s += NumberUtils.toDecimalFormat(product.cost);
        s += ' บาท \n';
        if(product_children_cost){
          s += product_children_cost + '\n';
        }
        s += 'ค่ารถ ';
        if(isFirstProduct){
        s +=  this.item.cost_shipping;
        }else{
          s+= '-';
        }
        s += ' บาท\n';
        
        s += 'ชื่อผู้สั่ง: ' + this.customer.name + '\n';
        s += 'จัดส่ง: ' + deliveryLocation + '\n';
        s += 'Google map: ' + google_map_url + '\n';
        s += 'งาน: ' + recipient + ' / ศาลา: ' + temple_hall +'\n';
        s += 'ข้อความป้าย: \n';
        s += 'บรรทัดที่ 1: ' + msg_fr  + '\n';
        s += 'บรรทัดที่ 2: ' + msg_se + '\n\n';
        s += 'ส่งวันที่: ' + DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY") + ' เวลา: ' + this.item.expected_delivery_time.slice(0, 5) + ' น.  จัดส่งถึงวัดไม่เกิน ' +deliveryTimeAddOneHour+ ' น.\n\n';
        s += '*ข้อเน้นย้ำซัพ: ' + caption + ', ' + wreathSize + '*';
        s += '\n-------------------------------------------------------------------------------------------------------';
        return s;
      }, 
      msg_product_tag_code_with_order_detail_for_customer_wreath(product,isFirstProduct,product_children_detail,product_total_price){ 
       
        let deliveryLocation = '';
        let wreathSize = '';
        if (this.products && this.products[0] && this.products[0].tags) {
          const tag = this.products[0].tags.find(tag => tag.key === 'ขนาดพวงหรีด');
          wreathSize = tag && tag.value ? tag.value : '';
        }
        const google_map_url = this.item.google_map_url ? this.item.google_map_url : '';
        const temple_hall = this.item.temple_hall ? this.item.temple_hall : '';
        const caption = product.caption ? product.caption : '';
        const recipient = product.recipient ? product.recipient : '';
        const deliveryTimeString = this.item.expected_delivery_time; 
        const [hours, minutes, seconds] = deliveryTimeString.split(':').map(Number);
        const deliveryTime = new Date();
        deliveryTime.setHours(hours, minutes, seconds || 0); 
 
        deliveryTime.setHours(deliveryTime.getHours() + 1);

        const deliveryTimeAddOneHour = deliveryTime.toTimeString().split(' ')[0].slice(0, 5);

        if(this.item.delivery_location){
          deliveryLocation += this.item.delivery_location + ' ';
        }
        if(this.item.delivery_address){
          deliveryLocation += this.item.delivery_address;
        }
        let s = '';
        if(isFirstProduct){
          s += 'เลขที่สั่งซื้อ: ' + this.item.code + '\n'; 
          s += 'กรุณาตรวจสอบสรุปรายการคำสั่งซื้อด้านล่าง หากมีข้อมูลไม่ถูกต้อง กรุณาแจ้งกลับภายใน 5 นาทีค่ะ \n\n';
        
          s += 'ชื่อผู้สั่ง: ' + this.customer.name + '/ เบอร์: ' + this.customer.phone + '\n';
          s += 'สถานที่จัดส่ง: ' + deliveryLocation + '\n';
          s += 'Google map: ' + google_map_url  + '\n\n';
          s += 'งาน: ' + recipient + ' / ศาลา: ' + temple_hall +'\n';
          s += '\n*ข้อเน้นย้ำ: ' + this.item.remark + ', ' + wreathSize + '*\n';
          s += 'ส่งวันที่: ' + DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY") + ' เวลา: ' + this.item.expected_delivery_time.slice(0, 5) + ' น.  จัดส่งถึงวัดไม่เกิน '+ deliveryTimeAddOneHour +' น.\n\n';
          s += 'เงื่อนไขการสั่งซื้อ : \n';
          s += 'ลูกค้าต้องการแก้ไขข้อมูลส่วนใดรบกวนแจ้งก่อนชำระเงินนะคะ หากมีการชำระเงินเรียบร้อยแล้วไม่มีข้อแก้ไขทางร้านขออนุญาตจัดทำออเดอร์ตามข้อมูลที่ทวนเลยนะคะ\n';
          s += '\n-------------------------------------------------------------------------------------------------------\n';
        }
        
        // Display product list
        s += '\nสั่งรหัส: ';
        s += product.product_code;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.price);
        s += ' บาท \n';
        if(product_children_detail){
          s += product_children_detail;
        }
        s += 'ข้อความ 1: ' + product.msg_fr  + '\n';
        s += 'ข้อความ 2: ' + product.msg_se + '\n';
        s += '*ข้อเน้นย้ำ:  ' + product.caption + '*\n\n';
        s += 'ยอดรวม '+ NumberUtils.toDecimalFormat(product_total_price) +' บาท\n';
        s += '\n-------------------------------------------------------------------------------------------------------';
        
        return s;
      }, 
      msg_product_tag_code_with_order_detail_for_supplier_flower(allProduct,product,isFirstProduct,product_children_cost,product_total_cost){
        
        let deliveryLocation = '';
       
        const google_map_url = this.item.google_map_url ? this.item.google_map_url : '';
        const temple_hall = this.item.temple_hall ? this.item.temple_hall : '';
        const caption = product.caption ? product.caption : '';
        const msg_fr = product.msg_fr ? product.msg_fr : '';
        const recipient = this.item.delivery_contact ? this.item.delivery_contact : '';
        const recipient_phone = this.item.delivery_phone ? this.item.delivery_phone : '';
        if(this.item.delivery_location){
          deliveryLocation += this.item.delivery_location + ' ';
        }
        if(this.item.delivery_address){
          deliveryLocation += this.item.delivery_address;
        }
        let s = '';
        
        s += 'ส่งออเดอร์()\n';
        s += 'เลขที่สั่งซื้อ: ' + this.item.code + '\n';
        s += ' รหัส: ';
        s += product.product_code;
        s += ' ส่งร้าน : ';
        s += NumberUtils.toDecimalFormat(product.cost);
        s += ' บาท \n';

        if(product_children_cost){
          s += product_children_cost + '\n';
        }

        s += 'ข้อความการ์ด: ' + msg_fr  + '\n';
        s += 'ค่ารถ ';
        if(isFirstProduct){
        s +=  this.item.cost_shipping;
        }else{
          s+= '-';
        }
        s += ' บาท\n';
        
        s += 'ผู้รับ: ' + recipient + ' /เบอร์: ' + recipient_phone +'\n';
        s += 'ส่งที่: ' + deliveryLocation + '\n';
        s += 'Google map: ' + google_map_url + '\n';
        s += 'ส่งวันที่: ' + DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY") + ' เวลา: ' + this.item.expected_delivery_time.slice(0, 5) + ' น. \n\n';
        s += '*ข้อเน้นย้ำ: ' + caption + '*';

        s += '\n***ขอรูปสินค้า+ข้อความการ์ด ก่อนออกจัดส่ง 30-45 นาที + รูปผู้รับ*** \n';
        s += 'รูปจัดเสร็จจะต้องรอทาง Call คอนเฟิร์มการจัดส่ง 15 นาที จึงออกจัดส่งได้ \n';

        s += '\n***ส่วนประกอบไหนที่ไม่มีรบกวนแจ้งกลับก่อนจัดทำ*** \n * สอบถามทางหรือติดปัญหาฉุกเฉินติดต่อกลับทาง Call เท่านั้น* \n กรณีติดต่อผู้รับรบกวนติดต่อเมื่อถึงที่จัดส่งเท่านั้น'
        s += '\n-------------------------------------------------------------------------------------------------------\n';
         
        return s;
      }, 
      msg_product_tag_code_with_order_detail_for_customer_flower(product,isFirstProduct,product_children,product_total_price){ 
       
        let deliveryLocation = '';
        console.log('msg_product_tag_code_with_order_detail_for_customer_flower');
        console.log('product', product);
        console.log('this.item', this.item);
      
        const google_map_url = this.item.google_map_url ? this.item.google_map_url : '';
        const temple_hall = this.item.temple_hall ? this.item.temple_hall : '';
        const caption = product.caption ? product.caption : '';
        const msg_fr = product.msg_fr ? product.msg_fr : '';
        if(this.item.delivery_location){
              deliveryLocation += this.item.delivery_location + ' ';
            }
            if(this.item.delivery_address){
              deliveryLocation += this.item.delivery_address;
            }
        let s = '';
        let product_total_price_display = product_total_price;
        if(isFirstProduct){
        s += 'เลขที่สั่งซื้อ: ' + this.item.code + '\n'; 
        s += 'รบกวนตรวจสอบข้อมูลการสั่งสินค้า  ทางร้านจะขอยึดข้อมูลส่งออเดอร์ตามนี้  นะคะ \n\n';
        s += 'ผู้สั่ง: ' + this.customer.name + ' / เบอร์: ' + this.customer.phone + '\n';
        s += 'ผู้รับ: ' + this.item.delivery_contact + ' / เบอร์: ' + this.item.delivery_phone + '\n\n';
        s += 'ส่งที่: ' + deliveryLocation + '\n';
        s += 'Google map: ' + google_map_url  + '\n\n';
        s += 'ส่งวันที่: ' + DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY") + ' เวลา: ' + this.item.expected_delivery_time.slice(0, 5) + ' น. \n';
        s += '*ข้อเน้นย้ำ: ' + this.item.remark + '*\n';
        s += '\n *** หากข้อมูลที่ทางเจ้าหน้าที่ทวนข้อมูลไม่ถูกต้อง รบกวนแจ้งกลับทันทีนะคะ *** \n';
        s += '(หากมีการยืนยันความถูกต้องแล้ว ไม่สามารถเปลี่ยนแปลงสินค้าได้ หากทางร้านจัดสินค้าเรียบร้อยแล้ว อาจมีค่าใช้จ่ายเพิ่มเติม) \n';
        s += '\n-------------------------------------------------------------------------------------------------------\n\n';
        }
       // Display product list
       s += '\nสั่ง รหัส: ';
        s += product.product_code;
        s += ' ราคา: ';
        s += NumberUtils.toDecimalFormat(product.price);
        s += ' บาท \n';
        if(product_children){
          s += product_children;
        }
        s += 'ข้อความการ์ด: ' + msg_fr  + '\n'; 
        s += '*ข้อเน้นย้ำ:  ' + caption + '*\n\n';
      
        s += 'ค่ารถ ';
        if(isFirstProduct){
          s += this.item.price_shipping;
          product_total_price_display = NumberUtils.toDecimalFormat( parseFloat(product_total_price) + parseInt(this.item.price_shipping));
        }else{
          s += '-';
        }
        s+= ' บาท\n';
        s += 'ราคารวม '+ product_total_price_display +' บาท\n';
        s += '\n-------------------------------------------------------------------------------------------------------';
       
        return s;
      }, 
      msg_product_detail(product){
        let s = '';
        s += '          ';
        s += '[';
        if(product.tags.length > 0)
          for(const tag of product.tags){
            if(tag.key == "ประเภท"){
              s += tag.value;
            }
          }
        s += ' รหัส: ';
        s += product.product_code;
       
        
        s += ' ]\n';
        s += '                    ';
        s += 'ข้อความ 1:';
        s += product.msg_fr;
        s += '\n';
        s += '                    ';
        s += 'ข้อความ 2:';
        s += product.msg_se;
        s += '\n';
        s += '                    ';
        s += 'ข้อเน้นย้ำ:';
        s += product.caption;
        s += '\n';
        s += '                    ';
        s += 'สี :';
        s += product.color;
        s += '\n';
        s += '                    ';
        s += 'ชื่อผู้รับ(ดอกไม้)/ชื่อผู้เสียชีวิต(พวงหรีด) :';
        s += product.recipient;
        s += '\n\n';
        return s;
      },
      display_all_product(){
        let s = '';
        for (const product of this.products) {
            s += product.product_code + ' ส่งร้าน ' + NumberUtils.toDecimalFormat(product.cost) + ' บาท\n';
        }
        s += '\n-------------------------------------------------------------------------------------------------------';
        return s;
      },
      async getItem(){
        const rst_delivery = await this.HttpServices.getData(`/ab_delivery/getDelvieryByOrderID/${this.item.id}`); 
        console.log("rst_delivery:",rst_delivery);
        if(rst_delivery&&rst_delivery.status.code=="200"){
          this.delivery_info = rst_delivery.data;
          this.item.id = rst_delivery.data.id;
        }

        
        const rst_refund= await this.HttpServices.getData(`/ab_refund/listbyorder/${this.item.id}`); 
        if(rst_refund&&rst_refund.status.code=="200"){
          this.refund_info = rst_refund.data;
        }

  
        const result = await this.HttpServices.getData(`/ab_template/getbystoreid/${this.item.store_id}`); 
        //const result = await this.HttpServices.getData(`/ab_template`);
        
        if(result&&result.status.code=="200"){

          if(this.item.fConfirmable){
            this.fConfirmable = this.item.fConfirmable;
          }
          
          const data = result.data;
          // this.item.delivery_date = DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY");
          // this.item.delivery_time = DateUtils.dateFormat(this.item.expected_delivery_time, "HH:mm");

         // this.order_info.date_order = DateUtils.dateFormat(this.item.date_order, "DD/MM/YYYY")
          

          let product = this.products[0];
          let product_children = '';
          let product_children_detail = '';
          let product_children_cost = '';
          let product_children_total_price = 0;
          let product_children_total_cost = 0;
          
          let product_set = '';
          let product_set_detail = '';
          let product_set_supplier = '';
          let product_set_supplier_detail = '';
          let product_wreath_set_supplier_with_order_detail = '';
          let product_wreath_set_customer_with_order_detail = '';
          let product_flower_set_supplier_with_order_detail = '';
          let product_flower_set_customer_with_order_detail = '';
          let isFirstProduct = false;
        
        
          for(let i in this.products){ 
            product_children = [];
            product_children_cost = [];
            product_set += '          ';
            product_set += this.msg_product_tag_code_price(this.products[i]);
            product_set += '\n';
            product_set_supplier += '          ';
            product_set_supplier += this.msg_product_tag_code_cost(this.products[i]);
            product_set_supplier += '\n';
            let product_total_price = 0;
            if(this.products[i].products.length > 0){
            for(let j in this.products[i].products){
                
                try{
                  this.products[i].products[j].imgs = JSON.parse(this.products[i].products[j].imgs);
                }catch{
                  this.products[i].products[j].imgs = [];
                }
                
                if(this.products[i].products[j].sub_cat==0){
                  product = this.products[i].products[j];
                }else{
                    product_children += '     - '
                    product_children += ' '+this.products[i].products[j].product_name;
                    product_children += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].price) + ' บาท';
                    product_children += '\n';
                    product_children_total_price += this.products[i].products[j].price;
                     
                    product_children_cost += '- '
                    product_children_cost += ' '+this.products[i].products[j].product_name;
                    product_children_cost += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].cost ? this.products[i].products[j].cost : 0) + ' บาท';
                    product_children_cost += '\n';
                  product_children_total_cost += this.products[i].products[j].cost; 
                  product_children_detail += this.msg_product_tag_code_price(this.products[i].products[j]) + '\n';
                }
              }   
            }
            if(i==0){
              isFirstProduct = true
            }else{
              isFirstProduct = false
            }
            product_total_price = this.item.total ? this.products[i].price+product_children_total_price : '-';
            
            product_children_total_price=0;
            let product_total_cost = this.item.total ? NumberUtils.toDecimalFormat(this.products[i].cost) : '-';
            
            product_wreath_set_supplier_with_order_detail  += this.msg_product_tag_code_with_order_detail_for_supplier_wreath(this.products,this.products[i],isFirstProduct,product_children_cost) + '\n';
            product_wreath_set_customer_with_order_detail  += this.msg_product_tag_code_with_order_detail_for_customer_wreath(this.products[i],isFirstProduct,product_children,product_total_price) + '\n';
            product_flower_set_supplier_with_order_detail  += this.msg_product_tag_code_with_order_detail_for_supplier_flower(this.products,this.products[i],isFirstProduct,product_children_cost,product_total_cost) + '\n';
            product_flower_set_customer_with_order_detail  += this.msg_product_tag_code_with_order_detail_for_customer_flower(this.products[i],isFirstProduct,product_children,product_total_price) + '\n';
            if(this.products[i].products.length > 0){
              for(let j in this.products[i].products){
                  product_set += '                    - ';
                  product_set += ' '+this.products[i].products[j].product_name;
                  product_set += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].price) + ' บาท';
                  product_set += '\n';
                  
                  product_set_supplier += '                    - ';
                  product_set_supplier += ' '+this.products[i].products[j].product_name;
                  product_set_supplier += ' '+NumberUtils.toDecimalFormat(this.products[i].products[j].cost) + ' บาท';
                  product_set_supplier += '\n';
                }
              }
              product_set_detail += this.msg_product_detail(this.products[i]);
            }


          var data_x =  [
            {
              "id": 1,
              "name": "template_customer",
              "value": data.copy_customer
            },{
              "id": 2,
              "name": "template_supplier",
              "value": data.copy_supplier
            },{
              "id": 3,
              "name": "template_bill",
              "value": data.copy_order
            }
          ];


          for(const v of data_x){
            let text = v.value;
            let images = '';
            if(product&&product.imageDisplays){
              if(product.imageDisplays){
                for(const vv of product.imageDisplays){
                  if(images){
                    images += '\n';
                  }
                  images += vv;
                }
              }
            }

            let cusType = this.customerTypes.find(v=>v.id==this.customer.customer_type);
            if(cusType){
              cusType = cusType.text;
            }

          
            let billingType = this.billingTypes.find(v=>v.id==this.item.status_receipt);
            if(billingType){
              billingType = billingType.text;
            }

            

            let deliveryLocation = '';
            if(this.item.delivery_location){
              deliveryLocation += this.item.delivery_location + ' ';
            }
            if(this.item.delivery_address){
              deliveryLocation += this.item.delivery_address;
            }

            console.log('this.item',this.item)
            
            text = text.replaceAll("[product_set]", product_set);
            text = text.replaceAll("[product_set_detail]", product_set_detail);
            text = text.replaceAll("[product_set_supplier]", product_set_supplier);
            text = text.replaceAll("[product_set_supplier_detail]", product_set_supplier_detail);


            text = text.replaceAll("[store_name]", this.item.store?this.item.store.name?this.item.store.name:data.store_name:data.store_name);
            text = text.replaceAll("[product_children]", product_children);
            text = text.replaceAll("[product_children_cost]", product_children_cost);
            text = text.replaceAll("[product_children_detail]", product_children_detail);
            text = text.replaceAll("[product_code]", product&&product.product_code?product.product_code:'');
            text = text.replaceAll("[order_contact]", this.item.customer_contact?this.item.customer_contact:'');
            text = text.replaceAll("[location_name]", deliveryLocation);
            text = text.replaceAll("[delivery_location]", deliveryLocation);
            text = text.replaceAll("[caption]", this.item.caption?this.item.caption:'');
            text = text.replaceAll("[recipient_name]", product&&product.recipient?product.recipient:'');
            text = text.replaceAll("[msg_fr]", product&&product.msg_fr?product.msg_fr:'');
            text = text.replaceAll("[msg_se]", product&&product.msg_se?product.msg_se:'');
            text = text.replaceAll("[product_remark]", product&&product.msg_se?product.remark:'');
            text = text.replaceAll("[product_caption]", product&&product.caption?product.caption:'');
            text = text.replaceAll("[delivery_date]", DateUtils.dateFormat(this.item.expected_delivery_date, "DD/MM/YYYY"));
            text = text.replaceAll("[delivery_time]", DateUtils.dateFormat(this.item.expected_delivery_time, "HH:mm"));
            text = text.replaceAll("[images]", images);//Array
            text = text.replaceAll("[bill_type]", billingType);
            text = text.replaceAll("[bill_name]", this.customer?this.customer.contact?this.customer.contact:'':'');
            text = text.replaceAll("[address]", this.item.supplier?this.item.supplier.tax_address?this.item.supplier.tax_address:'':'');
            text = text.replaceAll("[tax_id]", this.customer?this.customer.tax_id?this.customer.tax_id:'':'');
            text = text.replaceAll("[location_bill]", this.customer?this.customer.billing_address?this.customer.billing_address:'':'');
            text = text.replaceAll("[bill_location]", this.customer?this.customer.billing_address?this.customer.billing_address:'':'');
            text = text.replaceAll("[product_type]", "");
            text = text.replaceAll("[delivery_sender_name]", "");
            text = text.replaceAll("[delivery_name]", this.item.delivery_contact?this.item.delivery_contact?this.item.delivery_contact:'':'');
            text = text.replaceAll("[product_price]", product&&product.price?NumberUtils.toDecimalFormat(product.price):'-');
            text = text.replaceAll("[product_cost]", product&&this.item.cost_product?NumberUtils.toDecimalFormat(this.item.cost_product):'-');
            text = text.replaceAll("[wholesale_price_store]", product&&product.cost?NumberUtils.toDecimalFormat(product.cost):'-');
            text = text.replaceAll("[upsale_price]", "");
            text = text.replaceAll("[car_price]", this.item.service?NumberUtils.toDecimalFormat(this.item.service):'-');

            text = text.replaceAll("[total]", this.item.total_product_price?NumberUtils.toDecimalFormat(this.item.total_product_price):NumberUtils.toDecimalFormat(this.item.total));
            text = text.replaceAll("[total_cost]", this.item.total?NumberUtils.toDecimalFormat(product.cost+product_children_total_cost+this.item.cost_shipping):'-');
            text = text.replaceAll("[order_phone]", this.customer.phone?this.customer.phone:'');
            text = text.replaceAll("[delivery_contact]", this.item.delivery_contact?this.item.delivery_contact:'');
            text = text.replaceAll("[delivery_phone]", this.item.delivery_phone?this.item.delivery_phone:'');
            text = text.replaceAll("[delivery_address]", this.item.delivery_address?this.item.delivery_address:'');
            text = text.replaceAll("[remark]", this.item.remark?this.item.remark:'');
            text = text.replaceAll("[tracking_id]", this.item.tracking_id?this.item.tracking_id:'');
            text = text.replaceAll("[order_code]", this.item.code?this.item.code:'');
            text = text.replaceAll("[google_map_url]", this.item.google_map_url?this.item.google_map_url:'');

            text = text.replaceAll("[customer_email]", this.customer.email?this.customer.email:'');
            text = text.replaceAll("[customer_line]", this.customer.line?this.customer.line:'');
            text = text.replaceAll("[customer_phone]", this.customer.phone?this.customer.phone:'');
            text = text.replaceAll("[customer_name]", this.customer.name?this.customer.name:'');
            text = text.replaceAll("[customer_address]", this.customer.address?this.customer.address:'');
            text = text.replaceAll("[customer_billing_address]", this.customer.billing_address?this.customer.billing_address:'');
            text = text.replaceAll("[customer_billing_phone]", this.customer.billing_phone?this.customer.billing_phone:'');
            text = text.replaceAll("[customer_company_name]", this.customer.company_name?this.customer.company_name:'');
            text = text.replaceAll("[customer_contact]", this.customer.contact?this.customer.contact:'');
            text = text.replaceAll("[customer_credit_line]", this.customer.credit_line?this.customer.credit_line:'');
            text = text.replaceAll("[customer_credit_status]", this.customer.credit_status?this.customer.credit_status:'');
            text = text.replaceAll("[customer_tax_id]", this.customer.tax_id?this.customer.tax_id:'');
            text = text.replaceAll("[customer_customer_id]", this.customer.customer_id?this.customer.customer_id:'');
            text = text.replaceAll("[customer_customer_type]", this.customer.customer_type?this.customer.customer_type:'');
            text = text.replaceAll("[customer_remark]", this.customer.remark?this.customer.remark:'');

            text = text.replaceAll("[delivery_address]", this.item.delivery_address?this.item.delivery_address:'');
            text = text.replaceAll("[delivery_contact]", this.item.delivery_contact?this.item.delivery_contact:'');
            text = text.replaceAll("[delivery_location]", this.item.delivery_location?this.item.delivery_location:'');
            text = text.replaceAll("[delivery_location_id]", this.item.delivery_location_id?this.item.delivery_location_id:'');
            text = text.replaceAll("[delivery_phone]", this.item.delivery_phone?this.item.delivery_phone:'');
            text = text.replaceAll("[price_product]", this.item.price_product?this.item.price_product:'-');
            text = text.replaceAll("[price_shipping]", this.item.price_shipping?this.item.price_shipping:'-');
            text = text.replaceAll("[price_extra]", this.item.price_extra?this.item.price_extra:'-');
            text = text.replaceAll("[cost_product]", this.item.cost_product?this.item.cost_product:'-');
            text = text.replaceAll("[cost_shipping]", this.item.cost_shipping?this.item.cost_shipping:'-');
            text = text.replaceAll("[cost_extra]", this.item.cost_extra?this.item.cost_extra:'-');
            //text = text.replaceAll("[total]", this.item.total?this.item.total:'');
            text = text.replaceAll("[price_refund]", this.item.price_refund?this.item.price_refund:'');

            text = text.replaceAll("[cancel_by]", this.item.cancel_by?this.item.cancel_by:'');
            text = text.replaceAll("[cancel_id]", this.item.cancel_id?this.item.cancel_id:'');
            text = text.replaceAll("[cancel_remark]", this.item.cancel_remark?this.item.cancel_remark:'');

            text = text.replaceAll("[tax_id]", this.item.tax_id?this.item.tax_id:'');
            text = text.replaceAll("[billing_name]", this.item.customer_name?this.item.customer_name:'');
            text = text.replaceAll("[company_name]", this.item.company_name?this.item.company_name:'');
            text = text.replaceAll("[billing_phone]", this.item.customer.phone?this.item.customer.phone:'');
            text = text.replaceAll("[billing_address]", this.item.billing_address?this.item.billing_address:'');

            
            text = text.replaceAll("[donation_name]", this.item.donation_name?this.item.donation_name:'');
            text = text.replaceAll("[donation_data]", this.item.donation_data?this.item.donation_data:'');
            text = text.replaceAll("[receipt_remark]", this.item.receipt_remark?this.item.receipt_remark:'');
            text = text.replaceAll("[product_wreath_set_supplier_with_order_detail]", product_wreath_set_supplier_with_order_detail);
            text = text.replaceAll("[product_wreath_set_customer_with_order_detail]", product_wreath_set_customer_with_order_detail);
            text = text.replaceAll("[product_flower_set_supplier_with_order_detail]", product_flower_set_supplier_with_order_detail);
            text = text.replaceAll("[product_flower_set_customer_with_order_detail]", product_flower_set_customer_with_order_detail);
            text = text.replaceAll("[all_product]", this.display_all_product());
            const slipTime = new Date();
            slipTime.setMinutes(slipTime.getMinutes() + 30);
            const formattedSlipTime = DateUtils.dateFormat(slipTime, "HH:mm");
            text = text.replaceAll("[slip_time]", formattedSlipTime);
            const search_tags = text.match(/\[tag:[^\]]*\]/g);
            for(let i in search_tags){
              let tag_key = search_tags[i].replace('[tag:','').replace(']','');
              let tag_value = "-";

              if(this.products[0].tags.length > 0)
              for(const tag of this.products[0].tags){
                if(tag.key == tag_key){
                  tag_value = tag.value;
                }

              }
              text = text.replaceAll(search_tags[i],tag_value);

            }
            text = text.replace(/\[tag:[^\]]*\]/,'555555');

            if(this.delivery_info != ""){
              text = text.replaceAll("[actual_delivery_date]", DateUtils.dateFormat(this.delivery_info.actual_delivery_date, "DD/MM/YYYY"));
              text = text.replaceAll("[actual_delivery_time]", DateUtils.dateFormat(this.delivery_info.actual_delivery_time, "HH:mm"));
            }


          

            if(v.name=='template_customer'){
              this.templateCustomer = text;
            }else if(v.name=='template_supplier'){
              this.templateSupplier = text;
            }else if(v.name=='template_bill'){
              this.templateBill = text;
            }
          }
        }
      },
    
    async getItemOld(store_id) {
      const result = await this.HttpServices.getData(
        `/ab_template/getbystoreid/${store_id}`
      );
      //const result = await this.HttpServices.getData(`/ab_template`);
      if (result && result.status.code == "200") {
        if (this.itemOrder.fConfirmable) {
          this.fConfirmable = this.itemOrder.fConfirmable;
        }

        const data = result.data;
        this.itemOrder.delivery_date = DateUtils.dateFormat(
          this.itemOrder.expected_delivery_date,
          "DD/MM/YYYY"
        );
        this.itemOrder.delivery_time = DateUtils.dateFormat(
          this.itemOrder.expected_delivery_time,
          "HH:mm"
        );
        this.itemOrder.date_order = DateUtils.dateFormat(
          this.itemOrder.date_order,
          "DD/MM/YYYY"
        );

        this.products[0].imgs = JSON.parse(this.products[0].imgs);

        let product = this.products[0];
        let product_children = "";
        let product_children_cost = "";
        let product_children_total_price = 0;
        let product_children_total_cost = 0;

        if (this.products[0].products.length > 0) {
          for (let i in this.products[0].products) {
            this.products[0].products[i].imgs = JSON.parse(
              this.products[0].products[i].imgs
            );

            if (this.products[0].products[i].sub_cat == 0) {
              product = this.products[0].products[i];
            } else {
              product_children += "                    - ";
              product_children +=
                " " + this.products[0].products[i].product_name;
              product_children +=
                " " +
                NumberUtils.toDecimalFormat(
                  this.products[0].products[i].price
                ) +
                " บาท";
              product_children += "\n";
              product_children_total_price += this.products[0].products[i]
                .price;

              product_children_cost += "                    - ";
              product_children_cost +=
                " " + this.products[0].products[i].product_name;
              product_children_cost +=
                " " +
                NumberUtils.toDecimalFormat(this.products[0].products[i].cost) +
                " บาท";
              product_children_cost += "\n";
              product_children_total_cost += this.products[0].products[i].cost;
            }
          }
        }

        var data_x = [
          {
            id: 1,
            name: "template_customer",
            value: data.copy_customer
          },
          {
            id: 2,
            name: "template_supplier",
            value: data.copy_supplier
          },
          {
            id: 3,
            name: "template_bill",
            value: data.copy_order
          }
        ];

        for (const v of data_x) {
          let text = v.value;
          let images = "";
          if (product && product.imageDisplays) {
            if (product.imageDisplays) {
              for (const vv of product.imageDisplays) {
                if (images) {
                  images += "\n";
                }
                images += vv;
              }
            }
          }

          let cusType = this.customerTypes.find(
            v => v.id == this.customer.customer_type
          );
          if (cusType) {
            cusType = cusType.text;
          }

          let billingType = this.billingTypes.find(
            v => v.id == this.itemOrder.status_receipt
          );
          if (billingType) {
            billingType = billingType.text;
          }

          let deliveryLocation = "";
          if (this.itemOrder.delivery_location) {
            deliveryLocation += this.itemOrder.delivery_location + " ";
          }
          if (this.itemOrder.delivery_address) {
            deliveryLocation += this.itemOrder.delivery_address;
          }
          text = text.replaceAll(
            "[store_name]",
            this.itemOrder.store
              ? this.itemOrder.store.name
                ? this.itemOrder.store.name
                : ""
              : ""
          );
          text = text.replaceAll("[product_children]", product_children);
          text = text.replaceAll(
            "[product_children_cost]",
            product_children_cost
          );
          text = text.replaceAll(
            "[product_code]",
            product && product.product_code ? product.product_code : ""
          );
          text = text.replaceAll(
            "[order_contact]",
            this.itemOrder.customer_contact
              ? this.itemOrder.customer_contact
              : ""
          );
          text = text.replaceAll("[location_name]", deliveryLocation);
          text = text.replaceAll("[delivery_location]", deliveryLocation);
          text = text.replaceAll(
            "[caption]",
            this.itemOrder.caption ? this.itemOrder.caption : ""
          );
          text = text.replaceAll(
            "[recipient_name]",
            product && product.recipient ? product.recipient : ""
          );
          text = text.replaceAll(
            "[msg_fr]",
            product && product.msg_fr ? product.msg_fr : ""
          );
          text = text.replaceAll(
            "[msg_se]",
            product && product.msg_se ? product.msg_se : ""
          );
          text = text.replaceAll(
            "[delivery_date]",
            DateUtils.dateFormat(
              this.itemOrder.expected_delivery_date,
              "DD/MM/YYYY"
            )
          );
          text = text.replaceAll(
            "[delivery_time]",
            DateUtils.dateFormat(this.itemOrder.expected_delivery_time, "HH:mm")
          );
          text = text.replaceAll("[images]", images); //Array
          text = text.replaceAll("[bill_type]", billingType);
          text = text.replaceAll(
            "[bill_name]",
            this.customer
              ? this.customer.contact
                ? this.customer.contact
                : ""
              : ""
          );
          text = text.replaceAll(
            "[address]",
            this.itemOrder.supplier
              ? this.itemOrder.supplier.tax_address
                ? this.itemOrder.supplier.tax_address
                : ""
              : ""
          );
          text = text.replaceAll(
            "[tax_id]",
            this.customer
              ? this.customer.tax_id
                ? this.customer.tax_id
                : ""
              : ""
          );
          text = text.replaceAll(
            "[location_bill]",
            this.customer
              ? this.customer.billing_address
                ? this.customer.billing_address
                : ""
              : ""
          );
          text = text.replaceAll(
            "[bill_location]",
            this.customer
              ? this.customer.billing_address
                ? this.customer.billing_address
                : ""
              : ""
          );
          text = text.replaceAll("[product_type]", "");
          text = text.replaceAll("[delivery_sender_name]", "");
          text = text.replaceAll(
            "[delivery_name]",
            this.itemOrder.delivery_contact
              ? this.itemOrder.delivery_contact
                ? this.itemOrder.delivery_contact
                : ""
              : ""
          );
          text = text.replaceAll(
            "[product_price]",
            product && product.price
              ? NumberUtils.toDecimalFormat(product.price)
              : "-"
          );
          text = text.replaceAll(
            "[product_cost]",
            product && product.cost
              ? NumberUtils.toDecimalFormat(product.cost)
              : "-"
          );
          text = text.replaceAll(
            "[wholesale_price_store]",
            product && product.cost
              ? NumberUtils.toDecimalFormat(product.cost)
              : "-"
          );
          text = text.replaceAll("[upsale_price]", "");
          text = text.replaceAll(
            "[car_price]",
            this.itemOrder.service
              ? NumberUtils.toDecimalFormat(this.itemOrder.service)
              : "-"
          );

          text = text.replaceAll(
            "[total]",
            this.itemOrder.total
              ? NumberUtils.toDecimalFormat(
                  product.price +
                    product_children_total_price +
                    this.itemOrder.price_shipping
                )
              : "-"
          );
          text = text.replaceAll(
            "[total_cost]",
            this.itemOrder.total
              ? NumberUtils.toDecimalFormat(
                  product.cost +
                    product_children_total_cost +
                    this.itemOrder.cost_shipping
                )
              : "-"
          );
          text = text.replaceAll(
            "[order_phone]",
            this.customer.phone ? this.customer.phone : ""
          );
          text = text.replaceAll(
            "[delivery_contact]",
            this.itemOrder.delivery_contact
              ? this.itemOrder.delivery_contact
              : ""
          );
          text = text.replaceAll(
            "[delivery_phone]",
            this.itemOrder.delivery_phone ? this.itemOrder.delivery_phone : ""
          );
          text = text.replaceAll(
            "[delivery_address]",
            this.itemOrder.delivery_address
              ? this.itemOrder.delivery_address
              : ""
          );
          text = text.replaceAll(
            "[remark]",
            this.itemOrder.remark ? this.itemOrder.remark : ""
          );
          text = text.replaceAll(
            "[tracking_id]",
            this.itemOrder.tracking_id ? this.itemOrder.tracking_id : ""
          );
          text = text.replaceAll(
            "[order_code]",
            this.itemOrder.code ? this.itemOrder.code : ""
          );

          text = text.replaceAll(
            "[customer_email]",
            this.customer.email ? this.customer.email : ""
          );
          text = text.replaceAll(
            "[customer_line]",
            this.customer.line ? this.customer.line : ""
          );
          text = text.replaceAll(
            "[customer_phone]",
            this.customer.phone ? this.customer.phone : ""
          );
          text = text.replaceAll(
            "[customer_name]",
            this.customer.name ? this.customer.name : ""
          );
          text = text.replaceAll(
            "[customer_address]",
            this.customer.address ? this.customer.address : ""
          );
          text = text.replaceAll(
            "[customer_billing_address]",
            this.customer.billing_address ? this.customer.billing_address : ""
          );
          text = text.replaceAll(
            "[customer_billing_phone]",
            this.customer.billing_phone ? this.customer.billing_phone : ""
          );
          text = text.replaceAll(
            "[customer_company_name]",
            this.customer.company_name ? this.customer.company_name : ""
          );
          text = text.replaceAll(
            "[customer_contact]",
            this.customer.contact ? this.customer.contact : ""
          );
          text = text.replaceAll(
            "[customer_credit_line]",
            this.customer.credit_line ? this.customer.credit_line : ""
          );
          text = text.replaceAll(
            "[customer_credit_status]",
            this.customer.credit_status ? this.customer.credit_status : ""
          );
          text = text.replaceAll(
            "[customer_tax_id]",
            this.customer.tax_id ? this.customer.tax_id : ""
          );
          text = text.replaceAll(
            "[customer_customer_id]",
            this.customer.customer_id ? this.customer.customer_id : ""
          );
          text = text.replaceAll(
            "[customer_customer_type]",
            this.customer.customer_type ? this.customer.customer_type : ""
          );
          text = text.replaceAll(
            "[customer_remark]",
            this.customer.remark ? this.customer.remark : ""
          );

          text = text.replaceAll(
            "[delivery_address]",
            this.itemOrder.delivery_address
              ? this.itemOrder.delivery_address
              : ""
          );
          text = text.replaceAll(
            "[delivery_contact]",
            this.itemOrder.delivery_contact
              ? this.itemOrder.delivery_contact
              : ""
          );
          text = text.replaceAll(
            "[delivery_location]",
            this.itemOrder.delivery_location
              ? this.itemOrder.delivery_location
              : ""
          );
          text = text.replaceAll(
            "[delivery_location_id]",
            this.itemOrder.delivery_location_id
              ? this.itemOrder.delivery_location_id
              : ""
          );
          text = text.replaceAll(
            "[delivery_phone]",
            this.itemOrder.delivery_phone ? this.itemOrder.delivery_phone : ""
          );
          text = text.replaceAll(
            "[price_product]",
            this.itemOrder.price_product ? this.itemOrder.price_product : "-"
          );
          text = text.replaceAll(
            "[price_shipping]",
            this.itemOrder.price_shipping ? this.itemOrder.price_shipping : "-"
          );
          text = text.replaceAll(
            "[price_extra]",
            this.itemOrder.price_extra ? this.itemOrder.price_extra : "-"
          );
          text = text.replaceAll(
            "[cost_product]",
            this.itemOrder.cost_product ? this.itemOrder.cost_product : "-"
          );
          text = text.replaceAll(
            "[cost_shipping]",
            this.itemOrder.cost_shipping ? this.itemOrder.cost_shipping : "-"
          );
          text = text.replaceAll(
            "[cost_extra]",
            this.itemOrder.cost_extra ? this.itemOrder.cost_extra : "-"
          );
          //text = text.replaceAll("[total]", this.itemOrder.total?this.itemOrder.total:'');
          text = text.replaceAll(
            "[price_refund]",
            this.itemOrder.price_refund ? this.itemOrder.price_refund : ""
          );

          text = text.replaceAll(
            "[cancel_by]",
            this.itemOrder.cancel_by ? this.itemOrder.cancel_by : ""
          );
          text = text.replaceAll(
            "[cancel_id]",
            this.itemOrder.cancel_id ? this.itemOrder.cancel_id : ""
          );
          text = text.replaceAll(
            "[cancel_remark]",
            this.itemOrder.cancel_remark ? this.itemOrder.cancel_remark : ""
          );

          text = text.replaceAll(
            "[tax_id]",
            this.itemOrder.tax_id ? this.itemOrder.tax_id : ""
          );
          text = text.replaceAll(
            "[billing_name]",
            this.itemOrder.customer_name ? this.itemOrder.customer_name : ""
          );
          text = text.replaceAll(
            "[company_name]",
            this.itemOrder.company_name ? this.itemOrder.company_name : ""
          );
          text = text.replaceAll(
            "[billing_phone]",
            this.itemOrder.customer.phone ? this.itemOrder.customer.phone : ""
          );
          text = text.replaceAll(
            "[billing_address]",
            this.itemOrder.billing_address ? this.itemOrder.billing_address : ""
          );

          const search_tags = text.match(/\[tag:[^\]]*\]/g);
          for (let i in search_tags) {
            let tag_key = search_tags[i].replace("[tag:", "").replace("]", "");
            let tag_value = "-";

            if (this.products[0].tags.length > 0)
              for (const tag of this.products[0].tags) {
                if (tag.key == tag_key) {
                  tag_value = tag.value;
                }
              }
            text = text.replaceAll(search_tags[i], tag_value);
          }
          text = text.replace(/\[tag:[^\]]*\]/, "555555");

          if (v.name == "template_customer") {
            this.templateCustomer = text;
          } else if (v.name == "template_supplier") {
            this.templateSupplier = text;
          } else if (v.name == "template_bill") {
            this.templateBill = text;
          }
        }
      }
    }
  },
  async mounted() {
    $(document).ready(function() {
      let table = document.querySelector(
        `#dialog_deliveries_send_data_supplier`
      );
      let button = document.querySelector("#button");
      function selectNode(node) {
        let range = document.createRange();
        range.selectNodeContents(node);
        let select = window.getSelection();
        select.removeAllRanges();
        select.addRange(range);
      }
    });
  }
};
</script>
