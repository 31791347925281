<template lang="">
  <b-modal id="dialog_find_problem" size="lg">
    <template #modal-title>
      <h3><i class="fa fa-pen"></i> พบปัญหา - รอดำเนินการ</h3>
    </template>
    <div class="row">
      <div class="col-md-6">
        <div class="mb-4">
          <label class=" col-form-label">ความคิดเห็น</label>
          <div class="">
            <b-form-textarea
              name="comment"
              placeholder="ความคิดเห็น"
              v-model="item.comment"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <base-input
              name="comment"
              v-model="item.comment"
              :rules="{ required: true }"
              class="custom-input-valid-datepicker"
            ></base-input>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-4">
          <label class=" col-form-label">หมายเหตุ</label>
          <div class="">
            <b-form-textarea
              name="remark"
              placeholder="หมายเหตุ"
              v-model="item.remark"
              rows="3"
              max-rows="5"
            ></b-form-textarea>
            <base-input
              name="remark"
              v-model="item.remark"
              :rules="{ required: true }"
              class="custom-input-valid-datepicker"
            ></base-input>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="saved()" class="style_btn"
        ><i class="fa fa-check"></i> ตกลง</b-button
      >
      <b-button variant="secondary" @click="close()"
        ><i class="fa fa-times"></i> ยกเลิก</b-button
      >
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "delivery-view-dialog-find-problem",
  data() {
    return {
      data: {
        comment: "",
        remark: ""
      }
    };
  },
  props: ["nameId", "dialogId", "item"],
  methods: {
    async saved() {
      const params = {
        comment: this.item.comment,
        remark: this.item.remark
      };

      const result = await this.HttpServices.putData(
        `/ab_delivery/updateProblem/${this.item.id}`,
        params
      );
      if (result && result.status.code == "200") {
        this.AlertUtils.alert("success", "พบปัญหา - รอดำเนินการ");
        this.$bvModal.hide("dialog_find_problem");
        this.$emit("completed", true);
      } else {
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close() {
      this.$bvModal.hide(`dialog_find_problem`);
    }
  }
};
</script>
