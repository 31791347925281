<template>
  <div>
    <b-modal id="dialog_deliveries_close" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> ปิดการดำเนินการ</h3>
      </template>
      <div class="text-center">
        <p>ยืนยันการปิดการดำเนินการ</p>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saved()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'delivery-view-dialog-close',
  data () {
    return {}
  },
  props: ['item'],
  methods: {
    async saved () {
      const result = await this.HttpServices.putData(`/ab_delivery/updateClose/${this.item.id}`);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alert("success", "ปิดการดำเนินการสำเร็จ");
        this.$bvModal.hide('dialog_deliveries_close');
        this.$emit('completed', true);
      }else{
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close () {
      this.$bvModal.hide(`dialog_deliveries_close`);
    }
  }
}
</script>
