export default {
  delivery: {
    rows: [{
      id: 1,
      order_code: 'O-0001',
      supplier_id: 1,
      status: 0,
      status_name: 'Draf สร้างเพื่อเก็บข้อมูล',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2,
      products: [{
        id: 1,
        cat_id: 1,
        code: 'B124',
        name: 'บุษปวัน',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 2,
        cat_id: 2,
        code: 'A215',
        name: 'กุหลาบ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 3,
        cat_id: 2,
        code: 'A0XX',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 4,
        cat_id: 2,
        code: 'B0XX',
        name: 'พวงหรีดพัดลม',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 5,
        cat_id: 2,
        code: 'C0XX',
        name: 'พวงหรีดผ้า',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 6,
        cat_id: 1,
        code: 'D0XX',
        name: 'พวงหรีดต้นไม้',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 7,
        cat_id: 2,
        code: 'E0XX',
        name: 'พวงหรีดนาฬิกา',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 8,
        cat_id: 2,
        code: 'F0XX',
        name: 'พวงหรีดดอกไม้ประดิษฐ์',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 9,
        cat_id: 2,
        code: 'FN10x',
        name: 'จัดดอกไม้หน้าศพ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 10,
        cat_id: 2,
        code: 'A001',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }]
    }, {
      id: 2,
      order_code: 'O-0002',
      supplier_id: 1,
      status: 1,
      status_name: 'ส่งข้อมูลในซัพพลายเออร์แล้ว',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2,
      products: [{
        id: 1,
        cat_id: 1,
        code: 'B124',
        name: 'บุษปวัน',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 2,
        cat_id: 2,
        code: 'A215',
        name: 'กุหลาบ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 3,
        cat_id: 2,
        code: 'A0XX',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 4,
        cat_id: 2,
        code: 'B0XX',
        name: 'พวงหรีดพัดลม',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 5,
        cat_id: 2,
        code: 'C0XX',
        name: 'พวงหรีดผ้า',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 6,
        cat_id: 1,
        code: 'D0XX',
        name: 'พวงหรีดต้นไม้',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 7,
        cat_id: 2,
        code: 'E0XX',
        name: 'พวงหรีดนาฬิกา',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 8,
        cat_id: 2,
        code: 'F0XX',
        name: 'พวงหรีดดอกไม้ประดิษฐ์',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 9,
        cat_id: 2,
        code: 'FN10x',
        name: 'จัดดอกไม้หน้าศพ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 10,
        cat_id: 2,
        code: 'A001',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }]
    }, {
      id: 3,
      order_code: 'O-0003',
      supplier_id: 1,
      status: 2,
      status_name: 'รับรูปจัดเสร็จจากซัพพลายเออร์',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2
    }, {
      id: 4,
      order_code: 'O-0004',
      supplier_id: 1,
      status: 3,
      status_name: 'ส่งรูปจัดเสร็จแล้วให้ลูกค้า',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2
    }, {
      id: 5,
      order_code: 'O-0005',
      supplier_id: 1,
      status: 5,
      status_name: 'รับรูปจากซัพพลายเออร์',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2
    }, {
      id: 6,
      order_code: 'O-0006',
      supplier_id: 1,
      status: 7,
      status_name: 'ปิดการดำเนินการ',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2
    }, {
      id: 7,
      order_code: 'O-0007',
      supplier_id: 1,
      status: 4,
      status_name: 'Onhold - พบปัญหา',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2,
      products: [{
        id: 1,
        cat_id: 1,
        code: 'B124',
        name: 'บุษปวัน',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 2,
        cat_id: 2,
        code: 'A215',
        name: 'กุหลาบ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 3,
        cat_id: 2,
        code: 'A0XX',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 4,
        cat_id: 2,
        code: 'B0XX',
        name: 'พวงหรีดพัดลม',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 5,
        cat_id: 2,
        code: 'C0XX',
        name: 'พวงหรีดผ้า',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 6,
        cat_id: 1,
        code: 'D0XX',
        name: 'พวงหรีดต้นไม้',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 7,
        cat_id: 2,
        code: 'E0XX',
        name: 'พวงหรีดนาฬิกา',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 8,
        cat_id: 2,
        code: 'F0XX',
        name: 'พวงหรีดดอกไม้ประดิษฐ์',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 9,
        cat_id: 2,
        code: 'FN10x',
        name: 'จัดดอกไม้หน้าศพ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 10,
        cat_id: 2,
        code: 'A001',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }]
    }, {
      id: 8,
      order_code: 'O-0008',
      supplier_id: 1,
      status: 3,
      status_name: 'ส่งรูปจัดเสร็จแล้วให้ลูกค้า',
      created: '',
      updated: '',
      photo_before_submited: '',
      photo_before: [
        'https://cdn01.pinkoi.com/product/nF2gfk3z/0/1/640x530.jpg',
        'https://dsj1e5gc359pm.cloudfront.net/uploads/products/525/main/77d62009-15873688615923f149854fe96c9be1350b1895721e5da.jpg?w=800'
      ],
      photo_after_submited: '',
      photo_after: [
        'https://media.istockphoto.com/photos/flower-delivery-picture-id538560924?k=6&m=538560924&s=170667a&w=0&h=5g_ysYFwPjM9lixLqjm4mR7bJTy2YnPAYeTWX_mtD9Q=',
        'https://flowers-bangkok.com/wp-content/uploads/2020/09/Flower-Delivery-Bangkok-delivery-cost.jpg'
      ],
      comment: '',
      remark: '',
      date_order: '21/08/2020',
      time_order: '13:00',
      expected_delivery_date: '',
      expected_delivery_time: '',
      actual_delivery_date: '',
      actual_delivery_time: '',
      status_payment: 2,
      products: [{
        id: 1,
        cat_id: 1,
        code: 'B124',
        name: 'บุษปวัน',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 2,
        cat_id: 2,
        code: 'A215',
        name: 'กุหลาบ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 3,
        cat_id: 2,
        code: 'A0XX',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 4,
        cat_id: 2,
        code: 'B0XX',
        name: 'พวงหรีดพัดลม',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 5,
        cat_id: 2,
        code: 'C0XX',
        name: 'พวงหรีดผ้า',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 6,
        cat_id: 1,
        code: 'D0XX',
        name: 'พวงหรีดต้นไม้',
        status: 1,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: '-',
        size: 'M',
        tags: ['ลิลลี่'],
        // mapping_cost
        cost: '2000',
        supplier_id: 2,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 7,
        cat_id: 2,
        code: 'E0XX',
        name: 'พวงหรีดนาฬิกา',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 8,
        cat_id: 2,
        code: 'F0XX',
        name: 'พวงหรีดดอกไม้ประดิษฐ์',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 9,
        cat_id: 2,
        code: 'FN10x',
        name: 'จัดดอกไม้หน้าศพ',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }, {
        id: 10,
        cat_id: 2,
        code: 'A001',
        name: 'พวงหรีดดอกไม้สด',
        status: 0,
        imgs: '',
        brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
        detail: '',
        color: 'แดง',
        size: 'L',
        tags: [],
        // mapping_cost
        cost: '1500',
        supplier_id: 1,
        cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
      }]
    }],
    paging: {
      rowTotal: 1
    }
  },
  status_delivery: [{
    id: 1,
    name: 'รอรูปจัดเสร็จ'
  }, {
    id: 2,
    name: 'ส่งรูปจัดเสร็จแล้ว'
  }, {
    id: 3,
    name: 'รอรูปผู้รับ'
  }, {
    id: 4,
    name: 'ส่งรูปผู้รับแล้ว'
  }],
  status: [{
    id: 0,
    name: 'Draf สร้างเพื่อเก็บข้อมูล'
  }, {
    id: 1,
    name: 'ส่งข้อมูลในซัพพลายเออร์แล้ว'
  }, {
    id: 2,
    name: 'รับรูปจัดเสร็จจากซัพพลายเออร์'
  }, {
    id: 3,
    name: 'ส่งรูปจัดเสร็จแล้วให้ลูกค้า'
  }, {
    id: 4,
    name: 'Onhold - พบปัญหา'
  }, {
    id: 5,
    name: 'รับรูปจากซัพพลายเออร์'
  }, {
    id: 6,
    name: 'พบปัญหา - รอดำเนินการ'
  }, {
    id: 7,
    name: 'ปิดการดำเนินการ'
  }],
  status_payment: [{
    id: 1,
    name: 'รอชำระเงิน'
  }, {
    id: 2,
    name: 'ตรวจสอบการชำระเงินแล้ว'
  }, {
    id: 3,
    name: 'ยืนยันการชำระเงินแล้ว'
  }],
  types: [{
    id: 1,
    name: 'ชำระเต็มจำนวน'
  }, {
    id: 2,
    name: 'แบ่งจ่าย'
  }],
  issue_type: [{
    id: 1,
    name: 'ปัญหาภายนอก',
    remark: ''
  }, {
    id: 2,
    name: 'ปัญหาภายใน',
    remark: ''
  }, {
    id: 3,
    name: 'ปัญหาจากลูกค้า',
    remark: ''
  }, {
    id: 4,
    name: 'ปัญหาจากซัพพลายเออร์',
    remark: ''
  }],
  search_filters: {
    status_filter: [{
      filter_count: 1,
      id: 0,
      name: 'Draf สร้างเพื่อเก็บข้อมูล'
    }, {
      filter_count: 1,
      id: 1,
      name: 'ส่งข้อมูลในซัพพลายเออร์แล้ว'
    }, {
      filter_count: 1,
      id: 2,
      name: 'รับรูปจัดเสร็จจากซัพพลายเออร์'
    }, {
      filter_count: 1,
      id: 3,
      name: 'ส่งรูปจัดเสร็จแล้วให้ลูกค้า'
    }, {
      filter_count: 1,
      id: 4,
      name: 'Onhold - พบปัญหา'
    }, {
      filter_count: 1,
      id: 5,
      name: 'รับรูปจากซัพพลายเออร์'
    }, {
      filter_count: 1,
      id: 6,
      name: 'พบปัญหา - รอดำเนินการ'
    }, {
      filter_count: 1,
      id: 7,
      name: 'ปิดการดำเนินการ'
    }]
  }
}
