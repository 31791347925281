<template>
  <b-modal id="dialog_deliveries_image_preview" size="lg">
    <template #modal-title>
      <h3>
        <i class="fa fa-file-image"></i> รูป
      </h3>
    </template>
    <div class="text-center" style="padding: 40px;">
      <VueSlickCarousel
        :arrows="true"
        :dots="true"
        :autoplay="true"
        :swipeToSlide="true"
        :autoplaySpeed="5000"
      >
        <div v-for="(v, index) in images" :key="`list_deliveries_display_${index}`">
          <img :src="v.url" class="img-thumbnail" style="max-width: 180px" />
        </div>
      </VueSlickCarousel>
    </div>
    <template #modal-footer>
      <b-button variant="secondary" @click="close()">
        <i class="fa fa-times"></i> ยกเลิก
      </b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: "delivery-view-dialog-image-preview",
  data() {
    return {
      images: []
    };
  },
  methods: {
    onSetImagesHandler(images) {
      this.images = images;
    },
    close() {
      this.$bvModal.hide(`dialog_deliveries_image_preview`);
    }
  }
};
</script>

<style scoped>
.img-thumbnail {
  max-width: 180px;
}
</style>