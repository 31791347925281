<template>
  <div>
    <b-modal id="dialog_delivery_draft" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> Draft สร้างเพื่อเก็บข้อมูล</h3>
      </template>
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(saveHandler)">
          <div class="row">
            <div class="col-12">
              <div class="mb-4">
                <label class=" col-form-label">รหัสการสั่งซื้อ</label>
                <base-input name="order_code" placeholder="รหัสการสั่งซื้อ" v-model="item.code" disabled></base-input>
              </div>
              <div class="mb-4">
                <label class=" col-form-label">วันที่จัดส่งที่กำหนด</label>
                <base-input name="expected_delivery_date"
                            placeholder="วันที่จัดส่งที่กำหนด"
                            v-model="item.expected_delivery_date_short"
                            disabled />
              </div>
              <div class="mb-4">
                <label class=" col-form-label">เวลาจัดส่งที่กำหนด</label>
                <base-input name="expected_delivery_date"
                            placeholder="เวลาจัดส่งที่กำหนด"
                            v-model="item.expected_delivery_time_short"
                            disabled />
              </div>
              <div class="mb-4">
                <label class="form-label">วันที่จัดส่งจริง</label>
                <date-picker  :format="'DD/MM/YYYY'"
                              v-model="item.actual_delivery_date"
                              class="w-100"
                              input-class="form-control"
                              placeholder="วันที่จัดส่งจริง"
                              :append-to-body="false"
                              :popup-style="{left: 0}">
                </date-picker>
                <base-input name="actual_delivery_date" v-model="actual_date" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
              </div>
              <div class="mb-4">
                <label class="form-label">เวลาจัดส่งจริง</label>
                <date-picker  :format="'HH:mm'"
                              v-model="item.actual_delivery_time"
                              class="w-100"
                              input-class="form-control"
                              placeholder="เวลาจัดส่งจริง"
                              :append-to-body="false"
                              :popup-style="{left: 0}"
                              type="time">
                </date-picker>
                <base-input name="actual_delivery_time" v-model="actual_time" :rules="{required: true}" class="custom-input-valid-datepicker"></base-input>
              </div>
            </div>
          </div>
          <button ref="submitButton" style="display:none;">Submit</button>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button variant="primary" @click="onOutsideSubmit()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import DateUtils from '../../../util/dateUtils';
import dialogSendDataSupplier from './dialog.send.data.supplier';
export default {
  name: 'delivery-view-dialog-find-problem',
  data () {
    return {
      actual_date: '',
      actual_time: ''
    }
  },
  props: [
    'item',
  ],
  methods: {
    onOutsideSubmit(){
      this.$refs.submitButton.click();
    },
    async saveHandler () {
      const params = {
        actual_delivery_date: DateUtils.dateFormat(this.item.actual_delivery_date, "YYYY-MM-DD"),
        actual_delivery_time: DateUtils.dateFormat(this.item.actual_delivery_time, "HH:mm")
      };
      const result = await this.HttpServices.putData(`/ab_delivery/updateDraft/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alert("success", "Draft สร้างเพื่อเก็บข้อมูลสำเร็จ");
        this.$bvModal.hide('dialog_delivery_draft');
        this.$emit('completed', true);
      }else{
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close () {
      this.$bvModal.hide(`dialog_delivery_draft`);
    }
  },
  components: {
    dialogSendDataSupplier
  },
  watch: {
    'item.actual_delivery_date'(newVal){
      if(newVal){
        this.actual_date = ""+newVal;
      }else{
        this.actual_date = "";
      }
    },
    'item.actual_delivery_time'(newVal){
      if(newVal){
        this.actual_time = ""+newVal;
      }else{
        this.actual_time = "";
      }
    }
  }
}
</script>
